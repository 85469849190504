/* Block grid
--------- */

[class*="block-grid-"] {
  display: block;
  margin: -15px;
  padding: 0;
}
[class*="block-grid-"]:before,
[class*="block-grid-"]:after {
  content: " ";
  display: table;
}
[class*="block-grid-"]:after {
  clear: both;
}
.block-grid-item {
  display: inline;
  margin: 0;
  padding: 15px;
  height: auto;
  float: left;
  list-style: none;
}
.block-grid-xs-1 > .block-grid-item {
  width: 100%;
}
.block-grid-xs-1 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-1 > .block-grid-item:nth-of-type(1n+1) {
  clear: both;
}
.block-grid-xs-2 > .block-grid-item {
  width: 50%;
}
.block-grid-xs-2 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-2 > .block-grid-item:nth-of-type(2n+1) {
  clear: both;
}
.block-grid-xs-3 > .block-grid-item {
  width: 33.333333333333336%;
}
.block-grid-xs-3 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-3 > .block-grid-item:nth-of-type(3n+1) {
  clear: both;
}
.block-grid-xs-4 > .block-grid-item {
  width: 25%;
}
.block-grid-xs-4 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-4 > .block-grid-item:nth-of-type(4n+1) {
  clear: both;
}
.block-grid-xs-5 > .block-grid-item {
  width: 20%;
}
.block-grid-xs-5 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-5 > .block-grid-item:nth-of-type(5n+1) {
  clear: both;
}
.block-grid-xs-6 > .block-grid-item {
  width: 16.666666666666668%;
}
.block-grid-xs-6 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-6 > .block-grid-item:nth-of-type(6n+1) {
  clear: both;
}
.block-grid-xs-7 > .block-grid-item {
  width: 14.285714285714286%;
}
.block-grid-xs-7 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-7 > .block-grid-item:nth-of-type(7n+1) {
  clear: both;
}
.block-grid-xs-8 > .block-grid-item {
  width: 12.5%;
}
.block-grid-xs-8 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-8 > .block-grid-item:nth-of-type(8n+1) {
  clear: both;
}
.block-grid-xs-9 > .block-grid-item {
  width: 11.11111111111111%;
}
.block-grid-xs-9 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-9 > .block-grid-item:nth-of-type(9n+1) {
  clear: both;
}
.block-grid-xs-10 > .block-grid-item {
  width: 10%;
}
.block-grid-xs-10 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-10 > .block-grid-item:nth-of-type(10n+1) {
  clear: both;
}
.block-grid-xs-11 > .block-grid-item {
  width: 9.090909090909092%;
}
.block-grid-xs-11 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-11 > .block-grid-item:nth-of-type(11n+1) {
  clear: both;
}
.block-grid-xs-12 > .block-grid-item {
  width: 8.333333333333334%;
}
.block-grid-xs-12 > .block-grid-item:nth-of-type(n) {
  clear: none;
}
.block-grid-xs-12 > .block-grid-item:nth-of-type(12n+1) {
  clear: both;
}
@media (min-width: 768px) {
  .block-grid-sm-1 > .block-grid-item {
    width: 100%;
  }
  .block-grid-sm-1 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-1 > .block-grid-item:nth-of-type(1n+1) {
    clear: both;
  }
  .block-grid-sm-2 > .block-grid-item {
    width: 50%;
  }
  .block-grid-sm-2 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-2 > .block-grid-item:nth-of-type(2n+1) {
    clear: both;
  }
  .block-grid-sm-3 > .block-grid-item {
    width: 33.333333333333336%;
  }
  .block-grid-sm-3 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-3 > .block-grid-item:nth-of-type(3n+1) {
    clear: both;
  }
  .block-grid-sm-4 > .block-grid-item {
    width: 25%;
  }
  .block-grid-sm-4 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-4 > .block-grid-item:nth-of-type(4n+1) {
    clear: both;
  }
  .block-grid-sm-5 > .block-grid-item {
    width: 20%;
  }
  .block-grid-sm-5 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-5 > .block-grid-item:nth-of-type(5n+1) {
    clear: both;
  }
  .block-grid-sm-6 > .block-grid-item {
    width: 16.666666666666668%;
  }
  .block-grid-sm-6 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-6 > .block-grid-item:nth-of-type(6n+1) {
    clear: both;
  }
  .block-grid-sm-7 > .block-grid-item {
    width: 14.285714285714286%;
  }
  .block-grid-sm-7 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-7 > .block-grid-item:nth-of-type(7n+1) {
    clear: both;
  }
  .block-grid-sm-8 > .block-grid-item {
    width: 12.5%;
  }
  .block-grid-sm-8 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-8 > .block-grid-item:nth-of-type(8n+1) {
    clear: both;
  }
  .block-grid-sm-9 > .block-grid-item {
    width: 11.11111111111111%;
  }
  .block-grid-sm-9 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-9 > .block-grid-item:nth-of-type(9n+1) {
    clear: both;
  }
  .block-grid-sm-10 > .block-grid-item {
    width: 10%;
  }
  .block-grid-sm-10 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-10 > .block-grid-item:nth-of-type(10n+1) {
    clear: both;
  }
  .block-grid-sm-11 > .block-grid-item {
    width: 9.090909090909092%;
  }
  .block-grid-sm-11 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-11 > .block-grid-item:nth-of-type(11n+1) {
    clear: both;
  }
  .block-grid-sm-12 > .block-grid-item {
    width: 8.333333333333334%;
  }
  .block-grid-sm-12 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-sm-12 > .block-grid-item:nth-of-type(12n+1) {
    clear: both;
  }
}
@media (min-width: 992px) {
  .block-grid-md-1 > .block-grid-item {
    width: 100%;
  }
  .block-grid-md-1 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-1 > .block-grid-item:nth-of-type(1n+1) {
    clear: both;
  }
  .block-grid-md-2 > .block-grid-item {
    width: 50%;
  }
  .block-grid-md-2 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-2 > .block-grid-item:nth-of-type(2n+1) {
    clear: both;
  }
  .block-grid-md-3 > .block-grid-item {
    width: 33.333333333333336%;
  }
  .block-grid-md-3 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-3 > .block-grid-item:nth-of-type(3n+1) {
    clear: both;
  }
  .block-grid-md-4 > .block-grid-item {
    width: 25%;
  }
  .block-grid-md-4 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-4 > .block-grid-item:nth-of-type(4n+1) {
    clear: both;
  }
  .block-grid-md-5 > .block-grid-item {
    width: 20%;
  }
  .block-grid-md-5 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-5 > .block-grid-item:nth-of-type(5n+1) {
    clear: both;
  }
  .block-grid-md-6 > .block-grid-item {
    width: 16.666666666666668%;
  }
  .block-grid-md-6 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-6 > .block-grid-item:nth-of-type(6n+1) {
    clear: both;
  }
  .block-grid-md-7 > .block-grid-item {
    width: 14.285714285714286%;
  }
  .block-grid-md-7 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-7 > .block-grid-item:nth-of-type(7n+1) {
    clear: both;
  }
  .block-grid-md-8 > .block-grid-item {
    width: 12.5%;
  }
  .block-grid-md-8 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-8 > .block-grid-item:nth-of-type(8n+1) {
    clear: both;
  }
  .block-grid-md-9 > .block-grid-item {
    width: 11.11111111111111%;
  }
  .block-grid-md-9 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-9 > .block-grid-item:nth-of-type(9n+1) {
    clear: both;
  }
  .block-grid-md-10 > .block-grid-item {
    width: 10%;
  }
  .block-grid-md-10 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-10 > .block-grid-item:nth-of-type(10n+1) {
    clear: both;
  }
  .block-grid-md-11 > .block-grid-item {
    width: 9.090909090909092%;
  }
  .block-grid-md-11 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-11 > .block-grid-item:nth-of-type(11n+1) {
    clear: both;
  }
  .block-grid-md-12 > .block-grid-item {
    width: 8.333333333333334%;
  }
  .block-grid-md-12 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-md-12 > .block-grid-item:nth-of-type(12n+1) {
    clear: both;
  }
}
@media (min-width: 1200px) {
  .block-grid-lg-1 > .block-grid-item {
    width: 100%;
  }
  .block-grid-lg-1 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-1 > .block-grid-item:nth-of-type(1n+1) {
    clear: both;
  }
  .block-grid-lg-2 > .block-grid-item {
    width: 50%;
  }
  .block-grid-lg-2 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-2 > .block-grid-item:nth-of-type(2n+1) {
    clear: both;
  }
  .block-grid-lg-3 > .block-grid-item {
    width: 33.333333333333336%;
  }
  .block-grid-lg-3 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-3 > .block-grid-item:nth-of-type(3n+1) {
    clear: both;
  }
  .block-grid-lg-4 > .block-grid-item {
    width: 25%;
  }
  .block-grid-lg-4 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-4 > .block-grid-item:nth-of-type(4n+1) {
    clear: both;
  }
  .block-grid-lg-5 > .block-grid-item {
    width: 20%;
  }
  .block-grid-lg-5 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-5 > .block-grid-item:nth-of-type(5n+1) {
    clear: both;
  }
  .block-grid-lg-6 > .block-grid-item {
    width: 16.666666666666668%;
  }
  .block-grid-lg-6 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-6 > .block-grid-item:nth-of-type(6n+1) {
    clear: both;
  }
  .block-grid-lg-7 > .block-grid-item {
    width: 14.285714285714286%;
  }
  .block-grid-lg-7 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-7 > .block-grid-item:nth-of-type(7n+1) {
    clear: both;
  }
  .block-grid-lg-8 > .block-grid-item {
    width: 12.5%;
  }
  .block-grid-lg-8 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-8 > .block-grid-item:nth-of-type(8n+1) {
    clear: both;
  }
  .block-grid-lg-9 > .block-grid-item {
    width: 11.11111111111111%;
  }
  .block-grid-lg-9 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-9 > .block-grid-item:nth-of-type(9n+1) {
    clear: both;
  }
  .block-grid-lg-10 > .block-grid-item {
    width: 10%;
  }
  .block-grid-lg-10 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-10 > .block-grid-item:nth-of-type(10n+1) {
    clear: both;
  }
  .block-grid-lg-11 > .block-grid-item {
    width: 9.090909090909092%;
  }
  .block-grid-lg-11 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-11 > .block-grid-item:nth-of-type(11n+1) {
    clear: both;
  }
  .block-grid-lg-12 > .block-grid-item {
    width: 8.333333333333334%;
  }
  .block-grid-lg-12 > .block-grid-item:nth-of-type(n) {
    clear: none;
  }
  .block-grid-lg-12 > .block-grid-item:nth-of-type(12n+1) {
    clear: both;
  }
}


/* Tab pill
--------- */

[class*="tab-pill-"] {
  display: block;
  margin: 0;
  padding: 0;
}
[class*="tab-pill-"]:before,
[class*="tab-pill-"]:after {
  content: " ";
  display: table;
}
[class*="tab-pill-"]:after {
  clear: both;
}
.pill-item {
  background: #fff;
  border-bottom: 1px solid #ced3e0;
  border-right: 1px solid #ced3e0;
  border-top: 1px solid #ced3e0;
  display: inline;
  margin: 0;
  height: auto;
  float: left;
  list-style: none;
}
.pill-item:first-child {
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 1px solid #ced3e0;
}
.pill-item:last-child {
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pill-item.active { background: #343e4e; }
.pill-item > a {
  display: block;;
  padding: 8px 12px;
}
.pill-item.active > a { color: #fff; }
.tab-pill-xs-1 > .pill-item {
  width: 100%;
  border: 1px solid #ced3e0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.tab-pill-xs-1 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-1 > .pill-item:nth-of-type(1n+1) {
  clear: both;
}
.tab-pill-xs-2 > .pill-item {
  width: 50%;
}
.tab-pill-xs-2 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-2 > .pill-item:nth-of-type(2n+1) {
  clear: both;
}
.tab-pill-xs-3 > .pill-item {
  width: 33.333333333333336%;
}
.tab-pill-xs-3 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-3 > .pill-item:nth-of-type(3n+1) {
  clear: both;
}
.tab-pill-xs-4 > .pill-item {
  width: 25%;
}
.tab-pill-xs-4 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-4 > .pill-item:nth-of-type(4n+1) {
  clear: both;
}
.tab-pill-xs-5 > .pill-item {
  width: 20%;
}
.tab-pill-xs-5 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-5 > .pill-item:nth-of-type(5n+1) {
  clear: both;
}
.tab-pill-xs-6 > .pill-item {
  width: 16.666666666666668%;
}
.tab-pill-xs-6 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-6 > .pill-item:nth-of-type(6n+1) {
  clear: both;
}
.tab-pill-xs-7 > .pill-item {
  width: 14.285714285714286%;
}
.tab-pill-xs-7 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-7 > .pill-item:nth-of-type(7n+1) {
  clear: both;
}
.tab-pill-xs-8 > .pill-item {
  width: 12.5%;
}
.tab-pill-xs-8 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-8 > .pill-item:nth-of-type(8n+1) {
  clear: both;
}
.tab-pill-xs-9 > .pill-item {
  width: 11.11111111111111%;
}
.tab-pill-xs-9 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-9 > .pill-item:nth-of-type(9n+1) {
  clear: both;
}
.tab-pill-xs-10 > .pill-item {
  width: 10%;
}
.tab-pill-xs-10 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-10 > .pill-item:nth-of-type(10n+1) {
  clear: both;
}
.tab-pill-xs-11 > .pill-item {
  width: 9.090909090909092%;
}
.tab-pill-xs-11 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-11 > .pill-item:nth-of-type(11n+1) {
  clear: both;
}
.tab-pill-xs-12 > .pill-item {
  width: 8.333333333333334%;
}
.tab-pill-xs-12 > .pill-item:nth-of-type(n) {
  clear: none;
}
.tab-pill-xs-12 > .pill-item:nth-of-type(12n+1) {
  clear: both;
}
@media (min-width: 768px) {
  .tab-pill-sm-1 > .pill-item {
    width: 100%;
    border: 1px solid #ced3e0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .tab-pill-sm-1 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-1 > .pill-item:nth-of-type(1n+1) {
    clear: both;
  }
  .tab-pill-sm-2 > .pill-item {
    width: 50%;
  }
  .tab-pill-sm-2 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-2 > .pill-item:nth-of-type(2n+1) {
    clear: both;
  }
  .tab-pill-sm-3 > .pill-item {
    width: 33.333333333333336%;
  }
  .tab-pill-sm-3 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-3 > .pill-item:nth-of-type(3n+1) {
    clear: both;
  }
  .tab-pill-sm-4 > .pill-item {
    width: 25%;
  }
  .tab-pill-sm-4 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-4 > .pill-item:nth-of-type(4n+1) {
    clear: both;
  }
  .tab-pill-sm-5 > .pill-item {
    width: 20%;
  }
  .tab-pill-sm-5 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-5 > .pill-item:nth-of-type(5n+1) {
    clear: both;
  }
  .tab-pill-sm-6 > .pill-item {
    width: 16.666666666666668%;
  }
  .tab-pill-sm-6 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-6 > .pill-item:nth-of-type(6n+1) {
    clear: both;
  }
  .tab-pill-sm-7 > .pill-item {
    width: 14.285714285714286%;
  }
  .tab-pill-sm-7 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-7 > .pill-item:nth-of-type(7n+1) {
    clear: both;
  }
  .tab-pill-sm-8 > .pill-item {
    width: 12.5%;
  }
  .tab-pill-sm-8 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-8 > .pill-item:nth-of-type(8n+1) {
    clear: both;
  }
  .tab-pill-sm-9 > .pill-item {
    width: 11.11111111111111%;
  }
  .tab-pill-sm-9 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-9 > .pill-item:nth-of-type(9n+1) {
    clear: both;
  }
  .tab-pill-sm-10 > .pill-item {
    width: 10%;
  }
  .tab-pill-sm-10 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-10 > .pill-item:nth-of-type(10n+1) {
    clear: both;
  }
  .tab-pill-sm-11 > .pill-item {
    width: 9.090909090909092%;
  }
  .tab-pill-sm-11 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-11 > .pill-item:nth-of-type(11n+1) {
    clear: both;
  }
  .tab-pill-sm-12 > .pill-item {
    width: 8.333333333333334%;
  }
  .tab-pill-sm-12 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-sm-12 > .pill-item:nth-of-type(12n+1) {
    clear: both;
  }
}
@media (min-width: 992px) {
  .tab-pill-md-1 > .pill-item {
    width: 100%;
    border: 1px solid #ced3e0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .tab-pill-md-1 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-1 > .pill-item:nth-of-type(1n+1) {
    clear: both;
  }
  .tab-pill-md-2 > .pill-item {
    width: 50%;
  }
  .tab-pill-md-2 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-2 > .pill-item:nth-of-type(2n+1) {
    clear: both;
  }
  .tab-pill-md-3 > .pill-item {
    width: 33.333333333333336%;
  }
  .tab-pill-md-3 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-3 > .pill-item:nth-of-type(3n+1) {
    clear: both;
  }
  .tab-pill-md-4 > .pill-item {
    width: 25%;
  }
  .tab-pill-md-4 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-4 > .pill-item:nth-of-type(4n+1) {
    clear: both;
  }
  .tab-pill-md-5 > .pill-item {
    width: 20%;
  }
  .tab-pill-md-5 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-5 > .pill-item:nth-of-type(5n+1) {
    clear: both;
  }
  .tab-pill-md-6 > .pill-item {
    width: 16.666666666666668%;
  }
  .tab-pill-md-6 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-6 > .pill-item:nth-of-type(6n+1) {
    clear: both;
  }
  .tab-pill-md-7 > .pill-item {
    width: 14.285714285714286%;
  }
  .tab-pill-md-7 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-7 > .pill-item:nth-of-type(7n+1) {
    clear: both;
  }
  .tab-pill-md-8 > .pill-item {
    width: 12.5%;
  }
  .tab-pill-md-8 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-8 > .pill-item:nth-of-type(8n+1) {
    clear: both;
  }
  .tab-pill-md-9 > .pill-item {
    width: 11.11111111111111%;
  }
  .tab-pill-md-9 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-9 > .pill-item:nth-of-type(9n+1) {
    clear: both;
  }
  .tab-pill-md-10 > .pill-item {
    width: 10%;
  }
  .tab-pill-md-10 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-10 > .pill-item:nth-of-type(10n+1) {
    clear: both;
  }
  .tab-pill-md-11 > .pill-item {
    width: 9.090909090909092%;
  }
  .tab-pill-md-11 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-11 > .pill-item:nth-of-type(11n+1) {
    clear: both;
  }
  .tab-pill-md-12 > .pill-item {
    width: 8.333333333333334%;
  }
  .tab-pill-md-12 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-md-12 > .pill-item:nth-of-type(12n+1) {
    clear: both;
  }
}
@media (min-width: 1200px) {
  .tab-pill-lg-1 > .pill-item {
    width: 100%;
    border: 1px solid #ced3e0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .tab-pill-lg-1 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-1 > .pill-item:nth-of-type(1n+1) {
    clear: both;
  }
  .tab-pill-lg-2 > .pill-item {
    width: 50%;
  }
  .tab-pill-lg-2 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-2 > .pill-item:nth-of-type(2n+1) {
    clear: both;
  }
  .tab-pill-lg-3 > .pill-item {
    width: 33.333333333333336%;
  }
  .tab-pill-lg-3 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-3 > .pill-item:nth-of-type(3n+1) {
    clear: both;
  }
  .tab-pill-lg-4 > .pill-item {
    width: 25%;
  }
  .tab-pill-lg-4 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-4 > .pill-item:nth-of-type(4n+1) {
    clear: both;
  }
  .tab-pill-lg-5 > .pill-item {
    width: 20%;
  }
  .tab-pill-lg-5 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-5 > .pill-item:nth-of-type(5n+1) {
    clear: both;
  }
  .tab-pill-lg-6 > .pill-item {
    width: 16.666666666666668%;
  }
  .tab-pill-lg-6 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-6 > .pill-item:nth-of-type(6n+1) {
    clear: both;
  }
  .tab-pill-lg-7 > .pill-item {
    width: 14.285714285714286%;
  }
  .tab-pill-lg-7 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-7 > .pill-item:nth-of-type(7n+1) {
    clear: both;
  }
  .tab-pill-lg-8 > .pill-item {
    width: 12.5%;
  }
  .tab-pill-lg-8 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-8 > .pill-item:nth-of-type(8n+1) {
    clear: both;
  }
  .tab-pill-lg-9 > .pill-item {
    width: 11.11111111111111%;
  }
  .tab-pill-lg-9 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-9 > .pill-item:nth-of-type(9n+1) {
    clear: both;
  }
  .tab-pill-lg-10 > .pill-item {
    width: 10%;
  }
  .tab-pill-lg-10 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-10 > .pill-item:nth-of-type(10n+1) {
    clear: both;
  }
  .tab-pill-lg-11 > .pill-item {
    width: 9.090909090909092%;
  }
  .tab-pill-lg-11 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-11 > .pill-item:nth-of-type(11n+1) {
    clear: both;
  }
  .tab-pill-lg-12 > .pill-item {
    width: 8.333333333333334%;
  }
  .tab-pill-lg-12 > .pill-item:nth-of-type(n) {
    clear: none;
  }
  .tab-pill-lg-12 > .pill-item:nth-of-type(12n+1) {
    clear: both;
  }
}


.ui-tooltip { 
    background: #000;
    border: none;
    color: #fff;
    font-size: 11px;
}